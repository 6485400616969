import React from 'react'

import App from './main'

import getStore from '../store'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from '../containers/ErrorBoundary'
import { ThemeProvider } from 'styled-components'
import theme from '../components/theme'
export const { store, persistor } = getStore()

const Root = () => (
    <ErrorBoundary>
        <Provider store={store}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </BrowserRouter>
        </Provider>
    </ErrorBoundary>
)

export default Root
