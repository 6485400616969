import isPromise from '../utils/isPromise'

export default function errorMiddleware() {
    return (next) => (action) => {
        let result = next(action)
        if (!isPromise(result)) {
            return action
        }
        return result.catch((error) => {
            return error
        })
    }
}
