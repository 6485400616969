const colors = {
    black: {
        default: '#1f2023',
        1: '#1f2023',
        2: 'rgba(0, 0, 0, 0.16)',
        3: '#000000',
    },
    grey: {
        default: '#6e727e',
        1: 'rgba(31, 32, 35, 0.6)',
        2: 'rgba(31, 32, 35, 0.8)',
        3: '#545969',
        4: '#6e727e',
        5: '#f5f8f9',
    },
    green: {
        default: 'rgba(1, 137, 80, 0.08)',
        1: '#018950',
        2: '#6fd66d',
        3: '#398836',
        4: 'rgba(1, 137, 80, 0.08)',
        5: 'rgba(57, 136, 54, 0.08)',
    },
    orange: {
        default: 'rgba(247, 148, 26, 0.08)',
        1: '#eb8508',
        2: 'rgba(247, 148, 26, 0.08)',
        3: '#F2EDDC',
    },
    blue: {
        default: 'rgba(39, 117, 201, 0.08)',
        1: '#2775c9',
        2: 'rgba(39, 117, 201, 0.08)',
        3: 'rgba(0, 179, 228, 0.08)',
        4: '#11b2e4',
        5: '#00b3e4',
    },
    white: {
        1: '#ffffff',
    },
    red: {
        1: '#eb6262',
    },
    yellow: {
        default: '#F4ED7D',
        1: '#F4ED7D',
    },
}

const sizing = {
    flexible: '100%',
    large: '1140px',
    small: '165px',
    medium: '237px',
}

const fontSizes = {
    1: '0.46rem',
    2: '0.53rem',
    3: '0.57rem',
    4: '0.6rem',
    5: '0.64rem',
    6: '0.71rem',
    7: '1.07rem',
    8: '1.21rem',
    9: '1.42rem',
    10: '3.14rem',
    11: '0.78rem',
    12: '2.14rem',
    13: '1.28rem',
    14: '1.7rem',
    15: '0.92rem',
    16: '1.14rem',
    17: '1rem',
    18: '1.7rem',
    19: '4.2rem',
    20: '1.5rem',
    21: '1.25rem',
    22: '3.7rem',
    23: '1.389rem',
    24: '2.0833333333rem',
    25: '3.4722222222rem',
    26: '23px',
    27: '20px',
    28: '14px',
    29: '15px',
    30: '18px',
    31: '16px',
    32: '17px',
}

const thickness = {
    1: '100',
    2: '200',
    3: '300',
    4: '400',
    5: '500',
    6: '600',
    7: '700',
    8: '800',
    9: '900',
}

const theme = {
    colors,
    sizing,
    fontSizes,
    thickness,
}

export default theme
