import React, { Suspense, lazy, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

// import { useSelector } from 'react-redux'
// import Crypto from 'crypto'

// const Auth = lazy(() => import('../../containers/Authentication'))
// const Dashboard = lazy(() => import('../../containers/Dashboard'))
const SendFlow = lazy(() => import('../../containers/Remittance'))
const NotFound = lazy(() => import('../../components/errorBoundary/NotFound'))
// const TransactionSuccess = lazy(() =>
//     import('../../components/sendflow/forms/TransactionSuccess')
// )
// const TransferSuccess = lazy(() =>
//     import('../../components/sendflow/forms/TransferSuccess')
// )
// const ConversionSuccess = lazy(() =>
//     import('../../components/sendflow/convert/ConversionSuccess')
// )
const Profile = lazy(() => import('../../containers/Profile'))

// const VoucherTopSuccess = lazy(() =>
//     import('../../components/sendflow/voucher/success')
// )

const MainRoutes = () => {
    const history = useHistory()
    const pathname = history.location.pathname
    const search = history.location.search

    const dashboardRoutes = 'home|settings|transactions|balances|cards'
    const authRoutes =
        'register|verification-failed|login|verify|success|create-username|reset|verify-email|reset-password'
    const sendFlowRoutes = 'amount|email|recipient|review|pay|confirm'
    const allRoutes = `(${dashboardRoutes}|${authRoutes}|${sendFlowRoutes})`

    const expression = new RegExp(allRoutes, 'gi')

    useEffect(() => {
        const isRegisteredRoute = expression.test(pathname)
        const isRemittance = pathname.toLowerCase().includes('remittance')
        const externalURL = `${process.env.REACT_APP_COINPROFILE_APP_BASEURL}${pathname}${search}`
        if (isRegisteredRoute) {
            window.location.href = externalURL
        }
        if (isRemittance) {
            window.location.href = '/'
        }
        // eslint-disable-next-line
    }, [])

    const Loader = () => <div></div>
    return (
        <Switch>
            <Suspense fallback={<Loader />}>
                {/* <Route
                    exact
                    path="/transaction/success/:id"
                    component={TransactionSuccess}
                />
                <Route
                    exact
                    path="/transfer/success"
                    component={TransferSuccess}
                />
                <Route
                    exact
                    path="/conversion/success"
                    component={ConversionSuccess}
                />
                <Route
                    exact
                    path="/voucher/success"
                    component={VoucherTopSuccess}
                />
                <Route exact path="/profile/@:id" component={Profile} />
                <Route path={authRoutes} component={Auth} />
                <Route exact path={dashboardRoutes} component={Dashboard} /> */}
                <Route exact path="/@:id" component={Profile} />
                <Route exact path="/" component={SendFlow} />
                <Route exact path="/*" component={NotFound} />
            </Suspense>
        </Switch>
    )
}
export default MainRoutes
