import React from 'react'
import { Container, SectionOne, BigSubTitle } from '../home/elements'
import { Button } from '../header/elements'
import { ReactComponent as ErrorImage } from './asset/images/errorBoundary.svg'

const AppCrash = () => {
    const onRefresh = () => {
        window.history.go(0)
    }
    return (
        <Container style={{ height: '100%' }}>
            <SectionOne
                style={{
                    height: '100%',
                    paddingTop: '10%',
                    paddingBottom: 50,
                    overflowY: 'scroll',
                }}
            >
                <ErrorImage
                    style={{
                        margin: 'auto',
                        display: 'block',
                    }}
                />
                <BigSubTitle
                    style={{
                        fontFamily: "'Mabry Pro',sans-serif",
                        fontWeight: 500,
                        fontSize: 28,
                        lineHeight: '28px',
                        textAlign: 'center',
                        color: '#000000',
                        marginTop: 17,
                    }}
                >
                    Oops! Something went wrong
                </BigSubTitle>
                <Button
                    style={{ margin: 'auto', marginTop: 49 }}
                    outlined="true"
                    onClick={onRefresh}
                >
                    Try again
                </Button>
            </SectionOne>
        </Container>
    )
}

export default AppCrash
