import React from 'react'
import AppCrash from '../components/errorBoundary'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production', 'staging'],
    releaseStage: process.env.REACT_APP_ENVIRONMENT,
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export default ({ children }) => (
    <ErrorBoundary FallbackComponent={AppCrash}>{children}</ErrorBoundary>
)
